<template>
  <div
    ref="content"
    style="width: 350px;"
  >
    <h5 class="fw-bold">
      {{ fleetDetail.vehicle_number }}
    </h5>
    <div>
      <table>
        <tr>
          <td>Brand</td>
          <td class="px-2 py-1">
            :
          </td>
          <td>
            <img
              v-if="fleetDetail.brand"
              :src="getFleetBrandIcon(fleetDetail.brand)"
              alt=""
              style="max-height: 20px; max-width: 28px;"
              class="my-auto mx-auto"
            >
            <span class="text-capitalize ms-2">{{ fleetDetail.brand }}</span>
          </td>
        </tr>
        <tr>
          <td>Driver</td>
          <td class="px-2 py-1">
            :
          </td>
          <td>{{ fleetDetail.driver_name }}</td>
        </tr>
        <tr>
          <td>Datetime</td>
          <td class="px-2 py-1">
            :
          </td>
          <td>{{ datetime }}</td>
        </tr>
      </table>
    </div>
  </div>  
</template>

<script>
import { formatDateTime, getFleetBrandIcon } from '@/utils/helper';

export default {
  props: {
    fleetDetail: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    datetime() {
      return this.fleetDetail?.last_location?.created_at ? formatDateTime(this.fleetDetail?.last_location?.created_at) : ''
    },
  },
  methods: {
    formatDateTime,
    getFleetBrandIcon,
  },
};
</script>

