<template>
  <Layout :items="items">
    <SplitHeader :no-padding="true">
      <template #left>
        <div class="d-flex justify-content-between w-100 my-auto">
          <div class="d-flex">
            <b-button
              variant="primary"
              @click="goBack"
            >
              <i class="ri-arrow-left-line my-auto fw-bold" />
            </b-button>
            <h4 class="ms-3 my-auto">
              {{ siteDetail.name }}
            </h4>
          </div>
          <div class="d-flex">
            <div
              v-if="!isLoading"
              class="my-auto me-3 p-2 rounded fw-bold text-capitalize"
              style="background-color: white;"
            >
              <img
                :src="getSiteMarkerIcon(siteDetail.category)"
                alt=""
                style="max-height: 20px; max-width: 20px;"
                class="my-auto me-1"
              >
              {{ siteDetail.category ? capitalize(siteDetail.category) : '' }}
            </div>
            <b-button
              v-if="userRole <= 1"
              variant="outline-primary"
              class="me-2"
              data-bs-toggle="modal"
              href="#site-form-modal"
              @click="addSite"
            >
              Add Micro Site
            </b-button>
            <b-button
              v-if="userRole <= 1"
              variant="outline-info"
              class="me-2"
              data-bs-toggle="modal"
              href="#site-form-modal"
              @click="selectSite(siteDetail)"
            >
              <i class="ri-edit-2-line" />
            </b-button>
            <b-button
              v-if="userRole <= 1"
              variant="outline-danger"
              data-bs-toggle="modal"
              href="#delete-modal"
              @click="selectSite(siteDetail)"
            >
              <i class="ri-delete-bin-line" />
            </b-button>
          </div>
        </div>
      </template>
      <template #right>
        <div
          class="d-flex w-100" 
          style="border-left: 1px solid #CED4DA;"
        >
          <b-col
            class="nav-tab d-flex"
            :class="selectedTab == 'arrivedFleet' ? 'active' : ''"
            @click="selectedTab = 'arrivedFleet'"
          >
            <span class="m-auto fw-bold">
              Arrived Fleet
            </span>
          </b-col>
          <b-col
            class="nav-tab d-flex"
            :class="selectedTab == 'siteActivity' ? 'active' : ''"
            @click="selectedTab = 'siteActivity'"
          >
            <span class="m-auto fw-bold">
              Site Activity
            </span>
          </b-col>
        </div>
      </template>
    </SplitHeader>

    <div class="row">
      <div class="d-lg-flex">
        <b-col
          lg="6"
          class="pe-2"
          style="padding-top: 3.5rem;"
        >
          <b-card-body
            class="rounded"
            style="border: 1px #CED4DA solid;"
          >
            <div class="d-flex mb-2">
              <img
                src="@/assets/images/icons/address.svg"
                alt=""
                class="my-auto"
              >
              <div class="ms-2">
                {{ siteDetail.address }}
              </div>
            </div>
            <div class="d-flex">
              <img
                src="@/assets/images/icons/location.svg"
                alt=""
                class="my-auto"
              >
              <div class="ms-2">
                {{ siteDetail.latitude ?? '-' }}, 
                {{ siteDetail.longitude ?? '-' }}
              </div>
            </div>
          </b-card-body>
          <SiteMap
            v-if="!isLoading"
            class="mt-3"
            style="height: 500px"
            :site-detail="siteDetail"
            :latitude="siteDetail.latitude"
            :longitude="siteDetail.longitude"
            :fleet-list="arrivedFleets"
          />
          <SiteDetailMicroSiteTable
            v-if="!siteDetail.parent_id"
            class="mt-3"
            :sites="siteDetail.children"
            @add-micro-site="addSite"
            @edit-micro-site="selectSite"
            @delete-micro-site="selectSite"
          />
        </b-col>
        <b-col
          lg="6"
          class="ps-2"
          style="padding-top: 3.5rem; border-left: 1px solid #CED4DA;"
        >
          <div v-show="selectedTab == 'arrivedFleet'">
            <SiteDetailArrivedFleet
              :is-loading="isLoading"
              :arrived-fleets="arrivedFleets"
            />
          </div>
          <div v-show="selectedTab == 'siteActivity'">
            <SiteDetailLogs
              :site-detail="siteDetail"
              :active="selectedTab == 'siteActivity'"
            />
          </div>
        </b-col>
      </div>
    </div>
    <SiteFormModal
      v-if="userRole <= 1"
      :site-detail="selectedSite"
      :parent-detail="selectedParent"
      @update-data="getSiteDetail"
    />
    <DeleteModal 
      v-if="userRole <= 1"
      :entity="selectedSite.parent_id ? 'Micro Site' : 'Macro Site'"
      :parent="selectedParent.name"
      :name="selectedSite.name ?? ''"
      @delete-data="deleteData"
    />
  </Layout>
</template>
<script>
import { mapGetters } from 'vuex';
import { capitalize, getSiteMarkerIcon } from '@/utils/helper';

import { useToast } from 'vue-toastification';
import ToastDefault from '@/components/ToastDefault.vue';

import Layout from "@/layouts/main.vue";
import SplitHeader from "@/components/SplitHeader";
import SiteMap from "@/components/site/SiteMap";
import SiteDetailMicroSiteTable from '@/components/site/SiteDetailMicroSiteTable.vue';
import SiteDetailArrivedFleet from '@/components/site/SiteDetailArrivedFleet.vue';

import SiteFormModal from '@/components/site/SiteFormModal.vue';
import SiteDetailLogs from '@/components/site/SiteDetailLogs.vue';
import DeleteModal from '@/components/DeleteModal.vue';


export default {
  components: {
    Layout,
    SplitHeader,
    SiteDetailMicroSiteTable,
    SiteFormModal,
    DeleteModal,
    SiteMap,
    SiteDetailLogs,
    SiteDetailArrivedFleet
  },
  setup () {
    const toast = useToast();
    return {
      toast,
    }
  },
  data() {
    return {
      title: "Site Detail",
      items: [{
          text: "Site Management",
          href: "/site",
        },
        {
          text: "Site Detail",
          href: "/",
        },
      ],
      siteDetail: {},
      selectedSite: {},
      selectedParent: {},
      arrivedFleets: [],
      isLoading: false,      
      payloads: [],
      payloadMeta: {},
      selectedTab: 'arrivedFleet',
    }
  },
  computed: {
    ...mapGetters('organization', ['userRole']),
  },
  watch: {
    '$route.params': {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.organizationId && param.siteId) {
          this.getSiteDetail();
        }
      },
    },
    'siteDetail.parent_id': {
      deep: true,
      immediate: false,
      handler(val) {
        if(val) { 
          this.$router.push(`/organization/${this.$route.params.organizationId}/site/${val}`);
        }
      }
    }
  },
  methods: {
    capitalize, getSiteMarkerIcon,
    async getSiteDetail() {
      try {
        this.isLoading = true;
        const { data } = await this.$api.getPlaceDetail(this.$route.params.organizationId, this.$route.params.siteId);
        this.siteDetail = data.data;
        this.getArrivedFleets();
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getArrivedFleets() {
      try {
        this.isLoading = true;
        const { data } = await this.$api.getOrganizationDevices(this.$route.params.organizationId, {
          load_last_place_log: 1,
          last_place_log_status: 'arrival',
          last_place_id: this.siteDetail.id,
          per_page: 200,
        });
        this.arrivedFleets = data.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    async deleteData() {
      try {
        await this.$api.deleteOrganizationPlace(this.siteDetail.organization_id, this.selectedSite.id); 
        if (this.selectedSite.id == this.siteDetail.id) {
          // Delete the macro site
          this.$router.push('/site')
        } else {
          // Delete micro site
          this.getSiteDetail();
        }
        
        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'secondary',
            text: 'Deleted successfully',
            icon: 'ri-check-double-line',
          },
        };
        this.toast(toastContent);
      } catch (error) {
        console.log(error);

        const toastContent = {
          component: ToastDefault,
          props: {
            variant: 'danger',
            text: 'Process Failed',
          },
        };
        this.toast(toastContent);
      }
    },
    addSite() {
      this.selectedSite = {}
      this.selectedParent = this.siteDetail
    },
    selectSite(site) {
      this.selectedSite = site
      if(site.parent_id) {
        this.selectedParent = this.siteDetail
      } else { 
        this.selectedParent = {} 
      }
    },
    goBack() {
      if(this.$route.query.from) {        
        this.$router.push(`/dashboard`)
      } else if(this.$route.params.parentId) {        
        this.$router.push(`/organization/${this.siteDetail.organization_id}/site/${this.siteDetail.parent_id}`)
      } else {
        this.$router.push(`/site`)
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.nav-tab {
  cursor: pointer;
  &.active {
    background-color: #F3F6F9;
    border-bottom: 1px solid black;
  }
}
</style>