<template>
  <div>
    <div
      ref="map"
      style="height: 100%"
    />
    <div class="d-none">
      <InfoWindowSite
        ref="info-window-site"
        :show-address="false"
        :show-details-button="false"
        :site-detail="selectedSiteMarker"
      />
      <InfoWindowArrivedFleet
        ref="info-window-fleet"
        :fleet-detail="selectedFleetMarker"
      />
    </div>
  </div>
</template>
<script>
import InfoWindowSite from '@/components/tooltip/InfoWindowSite.vue';
import InfoWindowArrivedFleet from '@/components/tooltip/InfoWindowArrivedFleet.vue';
import { getSiteMarkerIcon, getSiteColor } from '@/utils/helper';
import { MarkerClusterer } from "@googlemaps/markerclusterer";

export default {
  components: {
    InfoWindowSite, 
    InfoWindowArrivedFleet
  },
  props: {
    latitude: {
      type: String,
      default: '0',
    },
    longitude: {
      type: String,
      default: '0',
    },
    isForm: {
      type: Boolean,
      default: false,
    },
    siteDetail: {
      type: Object,
      default() {
        return {}
      }
    },
    fleetList: {
      type: Array,
      default() { 
        return []
      }
    }
  },
  data() {
    return {
      selectedSiteMarker: {},
      selectedFleetMarker: {}
    }
  },
  computed: {
    siteGeofence() {
      if (this.siteDetail?.geofence?.coordinates[0]) {
        const geofence = this.siteDetail.geofence.coordinates[0]
        return geofence.map((item) => ({
          "lat": item[1],
          "lng": item[0]
        }))
      }
      return [] 
    },
    childrenSite() {
      return this.siteDetail.children.map(({ address, name, code, latitude, longitude, category, geofence }) =>({
          address,
          code,
          name,
          category,
          latitude,
          longitude,
          geofence: geofence?.coordinates[0]?.map(([lng, lat]) => ({ lat, lng })) || []
        }
      ));
    },
    infoWindowContent() {
      const infoWindow = this.$refs['info-window-site']
      return infoWindow.$refs['content']
    },
    infoWindowContentFleet() {
      const infoWindow = this.$refs['info-window-fleet']
      return infoWindow.$refs['content']
    },
  },
  watch: {
    latitude() {
      this.moveMarker()
    },
    longitude() {
      this.moveMarker()
    },
    fleetList: {
      deep: true,
      immediate: false,
      handler() {
        // Draw Arrived Fleets
        this.drawFleetMarker()
      }
    }
  },
  mounted () {
    this.initMap();
  },
  methods: {
    initMap() {
      window.map = new google.maps.Map(this.$refs['map'], {
        center: new google.maps.LatLng(Number(this.latitude), Number(this.longitude)),
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        mapTypeControl: true,
      })

      // Initiate info window
      window.infoWindow = new google.maps.InfoWindow({
        content: '',
      });
      
      // Create Bound
      window.bounds = new google.maps.LatLngBounds();
      bounds.extend(new google.maps.LatLng(this.latitude, this.longitude))
      map.fitBounds(bounds)
      
      // Marker Clusterer
      const markers = []
      window.markerCluster = new MarkerClusterer({map, markers});
      this.firstInit = true

      this.drawSiteMarker()
      this.drawChildrenSite()
      // Create geofence 
      if(this.siteGeofence.length) {
        this.drawGeofence(this.siteGeofence, this.siteDetail.category, !(!!this.siteDetail.parent_id))
      }
      // Add click event listener to move marker if map is on modal, emit clicked latlngs
      google.maps.event.addListener(map, "click", (event) => {
        if (event.placeId) {
          // Prevent tooltip when clicking point of interest places
          event.stop()
        }
        if (this.isForm) {
          this.handleClick(event.latLng);
        }
      });
    },
    handleClick(location) {
      const newLocation = { latitude: location.lat(), longitude: location.lng() }
      this.$emit('update-location', newLocation);
    },
    moveMarker() {
      marker.setPosition(new google.maps.LatLng(Number(this.latitude), Number(this.longitude)))
      if (!this.isForm) {
        map.setCenter(new google.maps.LatLng(Number(this.latitude), Number(this.longitude)))
      }
    },
    drawSiteMarker() {
      // Create Marker
      window.marker = new google.maps.Marker({
        position: new google.maps.LatLng(Number(this.latitude), Number(this.longitude)),
        map: window.map,
        icon: {
          url: this.isForm ? require('@/assets/images/googlemaps/site/default.svg') : getSiteMarkerIcon(this.siteDetail.category),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(13,13),
          scaledSize: new google.maps.Size(25, 25)
        }
      });
      
      marker.addListener("click", () => {
        // Update InfoWindow Content
        this.selectedSiteMarker = this.siteDetail
        window.infoWindow.setContent(this.infoWindowContent)
        // Show InfoWindow
        window.infoWindow.open({
          anchor: marker,
          map: window.map,
        });
      })
    },
    drawGeofence(geofence, category = null, isParent = false) {
      let newShape = new google.maps.Polygon({
        paths: geofence,
        geodesic: true,
        fillColor: getSiteColor(category),
        fillOpacity: 0.2,
        strokeColor: getSiteColor(category),
        strokeOpacity: 1,
        strokeWeight: isParent ? 4 : 2,
      });
      newShape.setMap(map)

      var paths = newShape.getPaths();
			paths.forEach(function(path) {
				var ar = path.getArray();
				for(var i = 0, l = ar.length;i < l; i++) {
					window.bounds.extend(ar[i]);
				}
			});
			map.fitBounds(bounds);
    },
    drawChildrenSite() {
      if(this.childrenSite.length) {
        this.childrenSite.forEach(item => {
          this.drawGeofence(item.geofence, item.category)
          const childMarker = new google.maps.Marker({
            position: new google.maps.LatLng(Number(item.latitude), Number(item.longitude)),
            map: window.map,
            icon: {
              url: getSiteMarkerIcon(item.category),
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(13,13),
              scaledSize: new google.maps.Size(20, 20)
            }
          })
          childMarker.addListener("click", () => {
            // Update InfoWindow Content
            this.selectedSiteMarker = item
            window.infoWindow.setContent(this.infoWindowContent)
            // Show InfoWindow
            window.infoWindow.open({
              anchor: childMarker,
              map: window.map,
            });
          })
        })
      }
    },
    drawFleetMarker() {
      // Draw children on map
      const fleetMarkers = []
      if(this.fleetList.length) {
        this.fleetList.forEach(item => {
          const fleetMarker = new google.maps.Marker({
            position: new google.maps.LatLng(Number(item.last_location?.coordinates[1] ?? 0), Number(item.last_location?.coordinates[0] ?? 0)),
            map: window.map,
            icon: {
              url: require('@/assets/images/googlemaps/fleet/fleet-purple.svg'),
              labelOrigin: new google.maps.Point(15, -15),
            },
            label: {
              text: item.vehicle_number,
              className : 'fw-bold text-primary',
              fontSize: '15px',
              fontFamily: 'Inter, sans-serif',
            }
          })
          fleetMarker.addListener("click", () => {
            // Update InfoWindow Content
            this.selectedFleetMarker = item
            window.infoWindow.setContent(this.infoWindowContentFleet)
            // Show InfoWindow
            window.infoWindow.open({
              anchor: fleetMarker,
              map: window.map,
            });
          })
          fleetMarkers.push(fleetMarker)
        })
      }
      if(window.markerCluster) {
        markerCluster.clearMarkers()
        markerCluster.addMarkers(fleetMarkers)
      }
    }
  }
}
</script>
