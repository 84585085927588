<template>
  <div>
    <DataTable
      :fields="tableFields"
      :items="processedArrivedFleets"
      :has-unit="true"
      :style="isLoading ? 'filter: opacity(0.4);' : ''"
    >
      <template #cell(brand)="{ row }">
        <div class="text-center w-100">
          <img
            v-if="row.item.brand"
            :src="getFleetBrandIcon(row.item.brand)"
            style="max-height: 20px; max-width: 28px;"
          >
          <div v-else>
            -
          </div>
        </div>
      </template>
      <template #cell(vehicle_number)="{ row }">
        <router-link
          :to="`/dashboard/?fleet=${row.item.id}`"
          class="link-item-table custom-link"
        >
          {{ row.item.vehicle_number }}
        </router-link>
      </template>
      <template #cell(arrival_time)="{ row }">
        {{ formatDateTime(row.item.arrival_time) ?? '-' }}
      </template>
      <template #cell(description)="{ row }">
        <span class="text-capitalize">
          {{ row.item.description }}
        </span>
      </template>
    </DataTable>
    <div
      v-if="!arrivedFleets.length && !isLoading"
      class="text-small text-center mb-3"
    >
      <div>There are no records to show.</div>
    </div>
  </div>
</template>
<script>
import { formatDateTime, getFleetBrandIcon } from '@/utils/helper';
import { utcOffset } from '@/utils/constant';

export default {
  components: {},
  props: {
    arrivedFleets: {
      type: Array,
      default() {
        return []
      },
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      utcOffset,
    }
  },
  computed: { 
    processedArrivedFleets() {
      return this.arrivedFleets.map(item => ({
        ...item,
        arrival_time: item.last_place_log?.created_at, // required for sort to function
      }))
    },
    tableFields() {
      return [
        { key: 'brand', label: ' ', unit: ' ', sortable: true },
        { key: 'vehicle_number', label: 'No Polisi', unit: 'Vehicle Number', sortable: true },
        { key: 'driver_name', label: 'Driver', unit: 'Name' },
        { key: 'arrival_time', label: 'Arrival Time', unit: utcOffset, class: 'text-break', sortable: true},
      ]
    },
  },
  methods: {
    formatDateTime, getFleetBrandIcon
  },
};
</script>
