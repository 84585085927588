<template>
  <div>
    <div class="d-flex justify-content-between gap-2 w-100 my-auto mb-3">
      <b-col>
        <FormDatePicker
          v-model="filter.start_date"
          label="Start"
          placeholder="Select Start Datetime"
        />
      </b-col>
      <b-col>
        <FormDatePicker
          v-model="filter.end_date"
          label="End"
          placeholder="Select End Datetime"
        />
      </b-col>
      <b-col class="d-flex gap-1 align-items-end">
        <b-button
          type="submit"
          variant="primary"
          class="w-100"
          @click="getSiteLogs"
        >
          Trace
        </b-button>
        <b-button
          class="w-100"
          variant="soft-danger"
          @click="resetForm"
        >
          Reset
        </b-button>
        <div class="flex-shrink-0 text-end pe-0">
          <DownloadDataButton
            :is-enabled="!!csvData.length"
            :data="csvData"
            :file-name="csvFileName"
          >
            <template #popper-content>
              Loading
            </template>
          </DownloadDataButton>
        </div>
      </b-col>
    </div>
    
    <DataTable
      :fields="tableFields"
      :items="processedPlaceLogs"
      :style="isLoading ? 'filter: opacity(0.4);' : ''"
    >
      <template #header(created_at)="{ row }">
        <div>
          {{ row.item.label }}
          <div
            class="fw-normal"
            style="white-space:nowrap"
          >
            {{ utcOffset }}
          </div>
        </div>
      </template>
      <template #cell(vehicle_number)="{ row }">
        <router-link
          :to="`/dashboard/?fleet=${row.item.device?.id}`"
          class="link-item-table custom-link"
        >
          {{ row.item.device?.vehicle_number }}
        </router-link>
      </template>
      <template #cell(created_at)="{ row }">
        {{ formatDateTime(row.item.created_at) }}
      </template>
      <template #cell(description)="{ row }">
        <span class="text-capitalize">
          {{ row.item.description }}
        </span>
      </template>
    </DataTable>
    <div
      v-if="!placeLogs.length && !isLoading"
      class="text-small text-center mb-3"
    >
      <div>There are no logs to show.</div>
      <div>Consider changing the uplink date.</div>
    </div>
    <div ref="componentEnd" />
  </div>
</template>
<script>
import { formatDateTime } from '@/utils/helper';
import { utcOffset } from '@/utils/constant';
import { format } from 'date-fns';

import DownloadDataButton from '@/components/DownloadDataButton.vue';
import FormDatePicker from '@/components/FormDatePicker.vue';

export default {
  components: {
    DownloadDataButton,
    FormDatePicker,
  },
  props: {
    siteDetail: {
      type: Object,
      required: true,
    },
    active: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      utcOffset,
      isLoading: false,
      filter: {
        start_date: format(new Date(), 'yyyy-MM-dd')+ " 00:00",
        end_date: format(new Date(), 'yyyy-MM-dd') + " 23:59",
      },
      placeLogs: [],
      placeLogsMeta: {},
    }
  },
  computed: { 
    processedPlaceLogs() {
      if (this.placeLogs.length) {
        return this.placeLogs.map(log => ({
          ...log,
          vehicle_number: log.device?.vehicle_number,
          driver_name: log.device?.driver_name,
          place_name: log.place?.name,
        }))
      } return this.placeLogs
    },
    tableFields() {
      return [
        { key: 'vehicle_number', label: 'No Polisi', class: 'text-break', sortable: true},
        { key: 'driver_name', label: 'Driver', class: 'text-break', sortable: true},
        { key: 'created_at', label: 'Datetime', class: 'text-break', sortable: true},
        { key: 'description', label: 'Status', class: 'fit'},
        { key: 'place_name', label: 'Site', class: 'text-break'},
      ]
    },
    csvData() {
      return this.placeLogs.map((log) => (
        {
          vehicle_number : log.device?.vehicle_number,
          driver_name : log.device?.driver_name,
          datetime : ` ${formatDateTime(log.created_at, true)}`,
          status : log.description,
          site: log.place?.name,
        }
      ))
    },
    csvFileName() {
      const start = format(new Date(this.filter.start_date), 'yyyyMMdd HHmmss') ?? ''
      const end = format(new Date(this.filter.end_date), 'yyyyMMdd HHmmss') ?? ''
      const name = this.siteDetail.name
      return `Site Log History - ${name} - ${start}_${end}`
    },
  },
  watch: {
    '$route.params': {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.organizationId && param.siteId) {
          this.getSiteLogs();
        }
      },
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    // remove event listener
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    formatDateTime,
    async getSiteLogs(page = 1) {
      this.isLoading = true
      if(page == 1) {
        this.placeLogs = []
      }
      try {
        const { data } = await this.$api.getPlaceLog(this.$route.params.organizationId, this.$route.params.siteId, { 
          start_date: new Date(this.filter.start_date).toISOString(),
          end_date: new Date(this.filter.end_date).toISOString(),
          method: 'geofence',
          page: page,
          per_page: 10,
         });
        this.placeLogs = this.placeLogs.concat(data.data)
        this.placeLogsMeta = data.meta;
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    resetForm() {
      this.filter.start_date = format(new Date(), 'yyyy-MM-dd')+ " 00:00";
      this.filter.end_date = format(new Date(), 'yyyy-MM-dd') + " 23:59";
      this.getSiteLogs();
    },
    handleScroll() {
      if (!this.active || this.isLoading) { return }
      const element = this.$refs['componentEnd']
      if(element.getBoundingClientRect().bottom < window.innerHeight) {
        // not last page
        if(this.placeLogsMeta.current_page < this.placeLogsMeta.last_page) {
          this.getSiteLogs(this.placeLogsMeta.current_page + 1)
        }
      } else { return }
    }
  },
};
</script>
