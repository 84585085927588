<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <div class="d-flex mt-3 mb-2 justify-content-between">
      <h5 class="custom-col p-2 m-0 w-100">
        Micro Site List
      </h5>
      <b-button
        variant="outline-primary"
        class="ms-2 flex-shrink-0"
        data-bs-toggle="modal"
        href="#site-form-modal"
        @click="$emit('add-micro-site')"
      >
        <i class="bx bx-plus" /> Add
      </b-button>
    </div>
    <DataTable
      :fields="tableFields"
      :items="sites"
    >
      <template #cell(category)="{ row }">
        {{ capitalize(row.item.category) }}
      </template>
      <template #cell(coordinate)="{ row }">
        {{ row.item.latitude }}, {{ row.item.longitude }}
      </template>
      <template #cell(action)="{ row }">
        <div class="d-flex flex-shrink-1">
          <b-button
            v-if="userRole <= 1"
            variant="outline-info"
            class="me-2"
            data-bs-toggle="modal"
            href="#site-form-modal"
            @click="$emit('edit-micro-site', row.item)"
          >
            <i class="ri-edit-2-line" />
          </b-button>
          <b-button
            v-if="userRole <= 1"
            variant="outline-danger"
            data-bs-toggle="modal"
            href="#delete-modal"
            @click="$emit('delete-micro-site', row.item)"
          >
            <i class="ri-delete-bin-line" />
          </b-button>
        </div>
      </template>
    </DataTable>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { capitalize } from '@/utils/helper';

export default {
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  props: {
    sites: {
      type: Array,
      default() {
        return []
      } 
    },
  },
  data() {
    return {
      tableFields: [{
          key: 'code',
          label: 'Code',
          class: 'text-break fit',
          sortable: true,
        }, {
          key: 'name',
          label: 'Name',
          class: 'text-break',
          sortable: true,
        }, {
          key: 'category',
          label: 'Type',
          class: 'text-break',
          sortable: true,
        }, {
          key: 'coordinate',
          label: 'Coordinate',
        }, {
          key: 'action',
          label: 'Action',
        },
      ],
    };
  },
  computed: {
    ...mapGetters('organization', ['userRole']),
  },
  methods: {
    capitalize
  },
};
</script>